<template>
	<div class="pui-form">
		<pui-form-header :showHeader="!isCreatingElement" v-if="modelLoaded">
			<imofalformsform-header v-model="model"></imofalformsform-header>
		</pui-form-header>
		<doc-new-version-modal :modelName="modelName" :pkAttribute="pkAttribute"></doc-new-version-modal>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-tabs-items v-model="tabmodel">
				<v-tab-item :key="0" lazy :value="'imofalform1menu'">
					<v-row class="pui-form-layout">
						<v-col xs12 lg12>
							<v-row dense>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
									<pui-select
										v-if="!this.isCreatingElement"
										:id="`imofalform1menu-portcall`"
										attach="imofalform1menu-portcall"
										:label="$t('imofalform1menu.portcall')"
										toplabel
										clearable
										disabled
										v-model="model"
										modelName="vlupportcalls"
										:modelFormMapping="{ portcallid: 'portcallid' }"
										:itemsToSelect="[{ portcallid: model.portcallid }]"
										:itemValue="['portcallid']"
										:itemText="(item) => `${item.visitid}`"
										:order="{ portcallid: 'desc' }"
									></pui-select>
									<pui-select
										v-if="this.isCreatingElement"
										:id="`imofalform1menu-portcall`"
										attach="imofalform1menu-portcall"
										:label="$t('imofalform1menu.portcall')"
										toplabel
										clearable
										:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid' || this.$route.params.parentpk"
										v-model="model"
										:modelName="isCreatingElement ? 'vlupportcalls' : 'vlupportcalls'"
										:modelFormMapping="{ portcallid: 'portcallid' }"
										:itemsToSelect="[{ portcallid: model.portcallid }]"
										:itemValue="['portcallid']"
										:itemText="(item) => `${item.visitid}`"
										:order="{ portcallid: 'desc' }"
										:fixedFilter="filterPortcalls"
									></pui-select>
								</v-col>
								<v-col class="col-6 col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2" style="padding-top: 20px; margin-left: 50px">
								</v-col>
								<v-col class="col-6 col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="padding-top: 20px; margin-left: -50px">
									<!--
									<pui-checkbox
										:id="`imofalform1menu-isarrival`"
										:label="$t('imofalform1menu.isarrival')"
										v-model="model.isarrival"
										:disabled="!this.isCreatingElement"
										true-value="1"
										false-value="0"
									></pui-checkbox>

									<pui-switch
										:label="
											this.model.indarrivaldeparture == 1 ? $t('imofalform1menu.isarrival') : $t('imofalform1menu.isdeparture')
										"
										:value="this.model.indarrivaldeparture"
										falseValue="1"
										trueValue="2"
										@input="(val) => (this.model.indarrivaldeparture = val)"
									></pui-switch>
									-->
									<v-radio-group
										v-if="this.isCreatingElement"
										v-model="model.indarrivaldeparture"
										:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
										row
										style="position: relative; top: 4px"
									>
										<v-radio
											:label="$t('imofalform1menu.isarrival')"
											:checked="selectedOption === $t('imofalform1menu.isarrival')"
											:value="'1'"
										></v-radio>
										<v-radio
											:label="$t('imofalform1menu.isdeparture')"
											:checked="selectedOption === $t('imofalform1menu.isdeparture')"
											:value="'2'"
										></v-radio>
									</v-radio-group>
									<v-radio-group
										v-if="!this.isCreatingElement"
										v-model="model.indarrivaldeparture"
										disabled
										row
										style="position: relative; top: 4px"
									>
										<v-radio
											:label="$t('imofalform1menu.isarrival')"
											:checked="selectedOption === $t('imofalform1menu.isarrival')"
											:value="'1'"
										></v-radio>
										<v-radio
											:label="$t('imofalform1menu.isdeparture')"
											:checked="selectedOption === $t('imofalform1menu.isdeparture')"
											:value="'2'"
										></v-radio>
									</v-radio-group>
								</v-col>

								<!--
									<pui-checkbox
										:id="`imofalform1menu-isdeparture`"
										:label="$t('imofalform1menu.isdeparture')"
										v-model="model.isdeparture"
										:disabled="!this.isCreatingElement"
										true-value="1"
										false-value="0"
									></pui-checkbox>
									-->
							</v-row>
							<v-row dense>
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('menufieldset.ship')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`imofalform1menu-shipname`"
													v-model="model.portcall.mship.shipname"
													:label="$t('imofalform1menu.shipname')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
												<pui-select
													id="`imofalform1menu-shiptype`"
													attach="imofalform1menu-shiptype"
													:label="$t('imofalform1menu.shiptype')"
													toplabel
													clearable
													disabled
													reactive
													v-model="model.portcall.mship"
													modelName="mshiptypes"
													:modelFormMapping="{ shiptypecode: 'shiptypecode' }"
													:itemsToSelect="itemsToSelectShipType"
													:itemValue="['shiptypecode']"
													:itemText="(item) => `${item.shiptypedesc}`"
													:order="{ shiptypedesc: 'asc' }"
												></pui-select>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2">
												<pui-text-field
													:id="`imofalform1menu-imo`"
													v-model="model.portcall.mship.imo"
													:label="$t('imofalform1menu.imo')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-1 col-xl-1">
												<pui-text-field
													:id="`imofalform1menu-callsign`"
													v-model="model.portcall.mship.callsign"
													:label="$t('imofalform1menu.callsign')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`imofalform1menu-flag`"
													attach="imofalform1menu-flag"
													:label="$t('imofalform1menu.flag')"
													toplabel
													disabled
													clearable
													reactive
													v-model="model.portcall"
													modelName="mcountries"
													:modelFormMapping="{ countrycode: 'flag' }"
													:itemsToSelect="itemsToSelectFlag"
													:itemValue="['countrycode']"
													:itemText="(item) => `${item.countrydesc}`"
													:order="{ countrydesc: 'asc' }"
												></pui-select>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`imofalform1menu-registryport`"
													attach="imofalform1menu-registryport"
													:label="$t('imofalform1menu.registryport')"
													toplabel
													disabled
													reactive
													clearable
													v-model="model.portcall.mship"
													modelName="mports"
													:modelFormMapping="{ countrycode: 'certregistrycountry', portcode: 'certregistryport' }"
													:itemsToSelect="itemsToSelectRegistryPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-date-field
													:id="`imofalform1menu-certregissueddate`"
													:label="$t('imofalform1menu.issuedate')"
													toplabel
													disabled
													v-model="model.documentsextr.issued"
												></pui-date-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`imofalform1menu-certificatenum`"
													v-model="model.documentsextr.docnumber"
													:label="$t('imofalform1menu.certificatenum')"
													toplabel
													disabled
												></pui-text-field>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-number-field
													:id="`imofalform1menu-grosstonnage`"
													v-model="model.portcall.mship.grosstonnage"
													:label="$t('imofalform1menu.grosstonnage')"
													toplabel
													disabled
													min="1"
												></pui-number-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`imofalform1menu-nettonnage`"
													disabled
													v-model="model.portcall.mship.nettonnage"
													:label="$t('imofalform1menu.nettonnage')"
													toplabel
													type="number"
												></pui-text-field>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<pui-text-area
													:id="`imofalform1menu-wasterequirements`"
													v-model="model.imofalform1.wasterequirements"
													:label="$t('imofalform1menu.wasterequirements')"
													:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
													toplabel
													maxlength="256"
												></pui-text-area>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
							<v-row dense style="margin-top: -15px">
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('menufieldset.voyage')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-text-field
													:id="`imofalform1menu-voyagenum`"
													v-model="model.portcall.voyagenum"
													:label="$t('imofalform1menu.voyagenum')"
													disabled
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-select
													id="`imofalform1menu-portofarrival`"
													attach="imofalform1menu-portofarrival"
													:label="this.labelportarrival"
													toplabel
													v-model="model.portcall"
													modelName="mports"
													disabled
													reactive
													:modelFormMapping="{ countrycode: 'countrycode', portcode: 'portcode' }"
													:itemsToSelect="itemsToSelectArrivalPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<v-col
												v-if="this.model.indarrivaldeparture === '1'"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-date-field
													:id="`imofalform1menu-arrivaldate`"
													:label="$t('imofalform1menu.arrivaldate')"
													toplabel
													time
													v-model="model.imofalform1.arrivaldate"
													disabled
												></pui-date-field>
											</v-col>
											<v-col
												v-if="this.model.indarrivaldeparture === '2'"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-date-field
													:id="`imofalform1menu-departuredate`"
													:label="$t('imofalform1menu.departuredate')"
													toplabel
													time
													v-model="model.imofalform1.departuredate"
													disabled
												></pui-date-field>
											</v-col>
											<v-col
												v-if="this.model.indarrivaldeparture === '1'"
												class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-select
													id="`imofalform1menu-lastport`"
													attach="imofalform1menu-lastport"
													:label="$t('imofalform1menu.lastport')"
													toplabel
													reactive
													disabled
													v-model="model.portcall"
													modelName="mports"
													:modelFormMapping="{ countrycode: 'previouscountry', portcode: 'previousport' }"
													:itemsToSelect="itemToSelectPreviousPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
											<v-col
												v-if="this.model.indarrivaldeparture === '2'"
												class="col-12 col-xs-12 col-sm-3 col-md-3 col-lg-3 col-xl-3"
											>
												<pui-select
													id="`imofalform1menu-nextport`"
													attach="imofalform1menu-nextport"
													:label="$t('imofalform1menu.nextport')"
													toplabel
													reactive
													v-model="model.portcall"
													disabled
													modelName="mports"
													:modelFormMapping="{ countrycode: 'nextcountry', portcode: 'nextport' }"
													:itemsToSelect="itemToSelectNextPort"
													:itemValue="['countrycode', 'portcode']"
													:itemText="(item) => `${item.locode} - ${item.portdescription}`"
													:order="{ locode: 'asc' }"
												></pui-select>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-if="this.isCreatingElement">
												<pui-text-field
													:id="`imofalform1menu-mastername`"
													v-model="model.portcall.mastername"
													:label="$t('imofalform1menu.mastername')"
													:disabled="!this.isCreatingElement && this.model.statusid != '1'"
													toplabel
													@input="updateMastername($event)"
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" v-else>
												<pui-text-field
													:id="`imofalform1menu-mastername`"
													v-model="model.imofalform1.imofalforms.mastername"
													:label="$t('imofalform1menu.mastername')"
													:disabled="this.model.statusid != '1'"
													toplabel
													@input="updateMastername($event)"
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-number-field
													:id="`imofalform1menu-numcrew`"
													v-model="model.imofalform1.numcrew"
													required
													:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
													:label="$t('imofalform1menu.numcrew')"
													toplabel
													min="1"
												></pui-number-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
												<pui-number-field
													:id="`imofalform1menu-numpassengers`"
													v-model="model.imofalform1.numpassengers"
													:label="$t('imofalform1menu.numpassengers')"
													toplabel
													:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
													integer
													required
												></pui-number-field>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<pui-text-area
													:label="$t('imofalform1menu.voyageparticulars')"
													:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
													v-model="model.imofalform1.voyageparticulars"
													toplabel
													maxlength="256"
												>
												</pui-text-area>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<pui-text-area
													:id="`imofalform1menu-cargodescription`"
													v-model="model.imofalform1.cargodescription"
													:label="$t('imofalform1menu.cargodescription')"
													:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
													required
													toplabel
													maxlength="256"
												></pui-text-area>
											</v-col>
										</v-row>
										<v-row dense style="margin-top: -15px">
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<pui-text-area
													:id="`imofalform1menu-observations`"
													v-model="model.imofalform1.observations"
													:label="$t('imofalform1menu.observations')"
													:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
													toplabel
													maxlength="256"
												></pui-text-area>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
							<v-row dense style="margin-top: -15px">
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('imofalform1menu.fieldset.agent')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
												<pui-text-field
													:id="`imofalform1menu-contactdetails`"
													v-model="model.imofalform1.contactdetails"
													:label="$t('imofalform1menu.contactdetails')"
													disabled
													toplabel
													maxlength="500"
												></pui-text-field>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
							<v-row dense style="margin-top: -15px" v-show="false">
								<v-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
									<pui-field-set :title="$t('imofalform1menu.fieldset.documents')">
										<v-row dense>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
												<pui-number-field
													:id="`imofalform1menu-cargodeclaration`"
													v-model="model.imofalform1.cargodeclaration"
													:label="$t('imofalform1menu.cargodeclaration')"
													toplabel
													:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
													min="1"
												></pui-number-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
												<pui-number-field
													:id="`imofalform1menu-numstores`"
													v-model="model.imofalform1.numstores"
													:label="$t('imofalform1menu.numstores')"
													toplabel
													:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
													min="1"
												></pui-number-field>
											</v-col>

											<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
												<pui-number-field
													:id="`imofalform1menu-numcrewlist`"
													v-model="model.imofalform1.numcrewlist"
													:label="$t('imofalform1menu.numcrewlist')"
													toplabel
													:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
													min="1"
													max="9999"
												></pui-number-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
												<pui-number-field
													:id="`imofalform1menu-numpassengerlist`"
													v-model="model.imofalform1.numpassengerlist"
													:label="$t('imofalform1menu.numpassengerlist')"
													toplabel
													:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
													min="0"
												></pui-number-field>
											</v-col>

											<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2" v-if="this.model.isarrival === '1'">
												<pui-text-field
													:id="`imofalform1menu-numeffects`"
													v-model="model.imofalform1.numeffects"
													:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
													:label="$t('imofalform1menu.numeffects')"
													toplabel
												></pui-text-field>
											</v-col>
											<v-col class="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2">
												<pui-text-field
													:id="`imofalform1menu-nummdh`"
													v-model="model.imofalform1.nummdh"
													:disabled="this.model.status == 'Submitted' || this.model.status == 'Invalid'"
													:label="$t('imofalform1menu.nummdh')"
													toplabel
												></pui-text-field>
											</v-col>
										</v-row>
									</pui-field-set>
								</v-col>
							</v-row>
						</v-col>
					</v-row>
				</v-tab-item>
			</v-tabs-items>

			<pui-form-footer>
				<nsw-imo-form-footer-btns
					:formDisabled="(this.model.status == 'Invalid' && !isCreatingElement) || !havePermissions(['WRITE_IMOFALFORMS1'])"
					:saveDisabled="saving"
					:value="model"
					:saveAndUpdate="saveAndUpdate"
					:showNext="false"
					:showDraft="this.model.status !== this.statusSubmitted"
					:showSubmit="this.model.status !== this.statusSubmitted"
					:showNewVersion="
						!isCreatingElement &&
						this.model.status === this.statusSubmitted &&
						this.model.status != 'Invalid' &&
						this.model.status != 'Submitted'
					"
					modelName="imofalform1Menu"
					:save="save"
					:back="back"
				></nsw-imo-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import { STATUS_DRAFT_DESC } from '../../constants/imofalforms/ImoFalFormsConstants';
import { STATUS_SUBMITTED_DESC } from '../../constants/imofalforms/ImoFalFormsConstants';
import imofalform1Actions from './ImoFalForm1Actions';
import docNewVersionModal from '@/components/nsw-components/DocumentNewVersionModal';

export default {
	name: 'imofalform1menuform',
	mixins: [PuiFormMethodsMixin],
	components: {
		DocNewVersionModal: docNewVersionModal
	},
	data() {
		return {
			modelName: 'imofalform1menu',
			actions: imofalform1Actions.actions,
			pkAttribute: 'imofalformid',
			modeldetail: true,
			tabmodel: 'imofalform1menu',
			labelportarrival: this.$t('imofalform1menu.portofarrival'),
			portCallDisableMasterName: false,
			portCallDisablePort: false,
			portCallDisableIssueDate: false,
			portCallDisableCertNum: false,
			statusDraftDesc: STATUS_DRAFT_DESC,
			statusSubmitted: STATUS_SUBMITTED_DESC,

			filtrado: ''
		};
	},
	mounted() {
		if (this.model.indarrivaldeparture === null) {
			this.model.indarrivaldeparture = '1';
		}

		this.$puiEvents.$on('onPuiSelect_selectedItems-imofalform1menu-portcall', (newVal) => {
			var lupaModel = newVal.model;
			if (this.isCreatingElement) {
				if (lupaModel !== null) {
					this.fillPortCallFields2(lupaModel);
				} else {
					this.clearPortCallFields(lupaModel);
				}
			} else {
				if (lupaModel !== null) {
					this.fillPortCallFields(lupaModel);
				} else {
					this.clearPortCallFields(lupaModel);
				}
			}
		});
	},
	computed: {
		filterPortcalls() {
			return {
				groups: [
					{
						groupOp: 'and',
						rules: [
							{ field: 'portcallstatus', op: 'ne', data: 0 },
							{ field: 'portcallstatus', op: 'ne', data: 6 },
							{ field: 'portcallstatus', op: 'ne', data: 3 }
						]
					}
				]
			};
		},
		itemsToSelectShipType() {
			return [{ shiptypecode: this.model.portcall.mship.shiptypecode }];
		},
		itemsToSelectArrivalPort() {
			return [{ countrycode: this.model.portcall.countrycode, portcode: this.model.portcall.portcode }];
		},
		itemsToSelectFlag() {
			return [{ countrycode: this.model.portcall.mship.flag }];
		},
		itemToSelectPreviousPort() {
			return [{ countrycode: this.model.portcall.previouscountry, portcode: this.model.portcall.previousport }];
		},
		itemToSelectNextPort() {
			return [{ countrycode: this.model.portcall.nextcountry, portcode: this.model.portcall.nextport }];
		},
		itemsToSelectRegistryPort() {
			return [
				{
					countrycode: this.model.portcall.mship.certregistrycountry,
					portcode: this.model.portcall.mship.certregistryport
				}
			];
		}
	},
	watch: {
		'model.indarrivaldeparture'() {
			if (this.model.indarrivaldeparture === '1') {
				this.labelportarrival = this.$t('imofalform1menu.portofarrival');
			} else {
				this.labelportarrival = this.$t('imofalform1menu.portofdeparture');
			}
		},
		'model.imofalform1.numpassengers'() {
			var campo = document.getElementById('imofalform1menu-numpassengers');
			if (campo) {
				campo.focus();
			} else {
				console.error('El elemento no existe.');
			}
		},

		'model.isarrival'() {
			if (this.model.isarrival === '1') {
				this.model.isdeparture = '0';
				this.model.indarrivaldeparture = '1';
				this.labelportarrival = this.$t('imofalform1menu.portofarrival');
			}
		},

		'model.isdeparture'() {
			if (this.model.isdeparture === '1') {
				this.model.isarrival = '0';
				this.model.indarrivaldeparture = '2';
				this.labelportarrival = this.$t('imofalform1menu.portofdeparture');
			}
		}
	},
	methods: {
		afterGetData() {
			if (this.$route.params.parentpk) {
				this.model.portcallid = JSON.parse(atob(this.$route.params.parentpk)).portcallid;
			}
		},
		havePermissions(permissions) {
			const allowedForms = this.$store.getters.getSession.functionalities;
			// Verificar si el usuario tiene permisos para todos los permisos especificados
			return permissions.every((permission) => allowedForms.includes(permission));
		},
		fillPortCallFields(lupaModel) {
			this.model.portcall.mship.shipname = lupaModel.shipname;
			this.model.portcall.mship.shiptypecode = lupaModel.shiptypecode;
			this.model.portcall.mship.imo = lupaModel.imo;
			this.model.portcall.mship.callsign = lupaModel.callsign;
			this.model.portcall.mship.shipid = lupaModel.shipid;
			this.model.portcall.mship.version = lupaModel.version;
			this.model.portcall.voyagenum = lupaModel.voyagenum;
			this.model.portcall.countrycode = lupaModel.countrycode;
			this.model.portcall.portcode = lupaModel.portcode;
			this.model.portcall.mship.flag = lupaModel.flag;
			this.model.imofalform1.arrivaldate = lupaModel.ata !== null ? lupaModel.ata : lupaModel.eta;
			this.model.imofalform1.departuredate = lupaModel.atd !== null ? lupaModel.atd : lupaModel.etd;
			this.model.portcall.countrycode = lupaModel.countrycode;
			this.model.portcall.mastername = lupaModel.mastername;
			this.model.portcall.mship.certregistrycountry = lupaModel.certregistrycountry;
			this.model.portcall.mship.certregistryport = lupaModel.certregistryport;
			this.model.portcall.mship.certregistrydate = lupaModel.certregistrydate;
			//this.model.documentsextr.docnumber = lupaModel.docnumber;
			//this.model.documentsextr.issued = lupaModel.issued;
			this.model.portcall.mship.certregistrynumber = lupaModel.certregistrynumber;
			this.model.portcall.mship.grosstonnage = lupaModel.grosstonnage;
			this.model.portcall.mship.nettonnage = lupaModel.nettonnage;
			this.model.portcall.previouscountry = lupaModel.previouscountry;
			this.model.portcall.previousport = lupaModel.previousport;
			this.model.portcall.nextcountry = lupaModel.nextcountry;
			this.model.portcall.nextport = lupaModel.nextport;
			this.model.portcall.mship.registryport = lupaModel.registryport;

			this.disableEnablePortCallsFields(lupaModel);
			//var campo = document.getElementById('imofalform1menu-numpassengers');
		},
		fillPortCallFields2(lupaModel) {
			this.model.portcall.mship.shipname = lupaModel.shipname;
			this.model.portcall.mship.shiptypecode = lupaModel.shiptypecode;
			this.model.portcall.mship.imo = lupaModel.imo;
			this.model.portcall.mship.callsign = lupaModel.callsign;
			this.model.portcall.mship.shipid = lupaModel.shipid;
			this.model.portcall.mship.version = lupaModel.version;
			this.model.portcall.mship.flag = lupaModel.flag;
			this.model.portcall.voyagenum = lupaModel.voyagenum;
			this.model.portcall.countrycode = lupaModel.countrycode;
			this.model.portcall.portcode = lupaModel.portcode;
			this.model.imofalform1.arrivaldate = lupaModel.ata !== null ? lupaModel.ata : lupaModel.eta;
			this.model.imofalform1.departuredate = lupaModel.atd !== null ? lupaModel.atd : lupaModel.etd;
			this.model.portcall.countrycode = lupaModel.countrycode;
			this.model.portcall.mastername = lupaModel.mastername;
			this.model.portcall.mship.certregistrycountry = lupaModel.certregistrycountry;
			this.model.portcall.mship.certregistryport = lupaModel.certregistryport;
			this.model.portcall.mship.certregistrydate = lupaModel.certregistrydate;
			this.model.documentsextr.docnumber = lupaModel.docnumber;
			this.model.documentsextr.issued = lupaModel.issued;
			this.model.portcall.mship.certregistrynumber = lupaModel.certregistrynumber;
			this.model.portcall.mship.grosstonnage = lupaModel.grosstonnage;
			this.model.portcall.mship.nettonnage = lupaModel.nettonnage;
			this.model.portcall.previouscountry = lupaModel.previouscountry;
			this.model.portcall.previousport = lupaModel.previousport;
			this.model.portcall.nextcountry = lupaModel.nextcountry;
			this.model.portcall.nextport = lupaModel.nextport;
			this.model.portcall.mship.registryport = lupaModel.registryport;
			this.model.indarrivaldeparture = '1';
			this.model.portcall.mship.flag = lupaModel.flag;

			this.disableEnablePortCallsFields(lupaModel);
		},
		clearPortCallFields(lupaModel) {
			this.model.portcall.mship.flag = null;
			this.model.portcall.mship.shipname = null;
			this.model.portcall.mship.shiptypecode = null;
			this.model.portcall.mship.imo = null;
			this.model.portcall.mship.callsign = null;
			this.model.portcall.mship.shipversion = null;
			this.model.portcall.voyagenum = null;
			this.model.portcall.countryofarrival = null;
			this.model.portcall.countryofdeparture = null;
			this.model.portcall.portofarrival = null;
			this.model.portcall.portofdeparture = null;
			this.model.imofalform1.arrivaldate = null;
			this.model.imofalform1.departuredate = null;
			this.model.portcall.countrycode = null;
			this.model.portcall.portcode = null;
			this.model.portcall.mastername = null;
			this.model.portcall.mship.certregcountrycode = null;
			this.model.portcall.mship.certregportcode = null;
			this.model.portcall.mship.certregissueddate = null;
			this.model.documentsextr.docnumber = null;
			this.model.documentsextr.issued = null;
			this.model.portcall.mship.certregnum = null;
			this.model.portcall.mship.grosstonnage = null;
			this.model.portcall.mship.nettonnage = null;
			this.model.portcall.previouscountry = null;
			this.model.portcall.previousport = null;
			this.model.portcall.nextcountry = null;
			this.model.portcall.nextport = null;
			this.model.portcall.mship.registryport = null;
			this.disableEnablePortCallsFields(lupaModel);
		},
		disableEnablePortCallsFields(lupaModel) {
			if (lupaModel === null) {
				this.portCallDisableMasterName = false;
				this.portCallDisablePort = false;
				this.portCallDisableIssueDate = false;
				this.portCallDisableCertNum = false;
			} else {
				this.portCallDisableMasterName = lupaModel.mastername === null ? false : true;
				this.portCallDisablePort = lupaModel.certregcountrycode === null || lupaModel.certregportcode === null ? false : true;
				this.portCallDisableIssueDate = lupaModel.certregissueddate === null || lupaModel.certregissueddate === undefined ? false : true;
				this.portCallDisableCertNum = lupaModel.certregnum === null || lupaModel.certregnum === undefined ? false : true;
			}
		},
		updateMastername(value) {
			this.model.mastername = value;
		}
	},

	destroyed() {
		this.$puiEvents.$off('onPuiSelect_selectedItems-imofalform1menu-portcall');
	}
};
</script>
